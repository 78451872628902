import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import * as styles from '../assets/css/home.module.css'
import { Trans } from 'gatsby-plugin-react-i18next'
import videogooglepay from "../assets/images/googlepay.png"
import navphone from "../assets/images/navphone.png"
import reading from "../assets/images/reading.png"
import book from "../assets/images/book.png"
import message from "../assets/images/message.png"
import grammer from "../assets/images/grammer.png"
import speaking from "../assets/images/speaking.png"
import clientone from "../assets/images/client-one.png"
import clienttwo from "../assets/images/client-two.png"
import clientthree from "../assets/images/client-three.png"
import clientfour from "../assets/images/clientfour.png"
import clientfive from "../assets/images/aif.png"
import clientsix from "../assets/images/dreamTn.png"
import clientseven from "../assets/images/kanavu.png"
import clienteight from "../assets/images/maatram.png"
import clientnine from "../assets/images/naandi.png"
import clientten from "../assets/images/rotary.png"
import clientleven from "../assets/images/Tech Mahindra Foundation.png"
import LearnEnglishwithZeroDoubts from "../assets/videos/1_LearnEnglishwithZeroDoubts.mp4"
import curriculumcustomized from "../assets/videos/2_A curriculum customized.mp4"
import TalkingYakSpeech from "../assets/videos/3_Talking Yak Speech.mp4"
import PersonalisedYak from "../assets/videos/4_Personalised Yak.mp4"
import custumised from "../assets/images/custumised-new.gif"
import speach from "../assets/images/speach.gif"
import videoimage1 from "../assets/images/videoimage1.png"
import { I18nextContext, useI18next } from 'gatsby-plugin-react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay } from 'swiper';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


export default function Home() {

	const {language, originalPath} = React.useContext(I18nextContext);
	
  return (
   <Layout>
    <div className={styles.home}>
        <div className="home-sectionone-wraper">
            <div className="row home-sectionone-wraped container-max-width">
				<div className="col-md-5 left-block">
					<h1 className="subtitle">
						<span className="hightlight-block">
							<span className={(language==="en")?"active":""}>The smartest, most efficient way to learn <span className="highlighted-text">English</span>.</span>
							<span className={(language==="hi")?"active":""}><span className="highlighted-text">अंग्रेज़ी</span> सीखने का स्मार्ट और सबसे कुशल तरीका</span>
							<span className={(language==="ta")?"active":""}>புத்திசாலித்தனமான, அதீதத் திறன்வாய்ந்த <span className="highlighted-text">ஆங்கிலம்</span> கற்கும் முறை</span>
						</span>
					</h1>
					<div className="descptionwraper">
						<Trans>By using Talking Yak for as little as 15 minutes per day, you can gain the English skills necessary to maximize your future. Click on the video to see how Talking Yak works and download the app for free.</Trans>
					</div>
					<div className="btn-linksectionwraper">
						<div className="downloadlinkwraper witharrow">
							<Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><span className="link-text"><Trans>Download Now</Trans></span><span className="link__icon"></span></Link>
						</div>
						<div className="googlepay-linkwraper">
							<Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><img src={videogooglepay} alt="googlepay" /></Link>
						</div>
					</div>
				</div>
				<div className="col-md-7 right-block">
					<div className="home-banner-videosection-wraper video-sectionwraper">
						<div className={(language==="en")?"active":""}>
							<div className="vimeovideoWraper">
								<iframe src="https://player.vimeo.com/video/681193481?h=d5df8597b4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Talking Yak (English)"></iframe>
							</div><script src="https://player.vimeo.com/api/player.js"></script>
						</div>
						<div className={(language==="hi")?"active":""}>
							<div className="vimeovideoWraper"><iframe src="https://player.vimeo.com/video/681206691?h=53229d6b73&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Talking Yak (Hindi)"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
						</div>
						<div className={(language==="ta")?"active":""}>
							<div className="vimeovideoWraper"><iframe src="https://player.vimeo.com/video/681207608?h=3373c67f05&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Talking Yak (Tamil)"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
						</div>
					</div>
				</div>
            </div>  
        </div>
		<div className="home-sectiontwo-wraper">
            <div className="row home-sectiontwo-wraped container-max-width">
				<div className="col-md-12 home-sectiontwo-wraped-inner">
					<h3 className="subtitle">
						<Trans>94% of teachers surveyed recommend Talking Yak to learn English.</Trans>
					</h3>
					<div className="descptionwraper">
						<Trans>Talking Yak’s content, presented in Hindi or Tamil, and its adaptive technology are interwoven to rapidly teach and reinforce the concepts and knowledge that learners require to successfully function in a 100% English speaking environment.</Trans>
					</div>
				</div>
            </div>  
        </div>
		<div className="home-sectionthree-wraper blocksection-mainwraper">
			<div className="row block-wraper image-descriptionwraper section_one">
				<div className="left-block image-block col-md-6">
					<div className="video-sectionwraper">
						<video id="testvideo" loop="true" autoplay="autoplay" muted>
							<source src={LearnEnglishwithZeroDoubts} type="video/mp4" />
							<track src={LearnEnglishwithZeroDoubts} kind="LearnEnglishwithZeroDoubts" srclang="en" label="english_captions"></track>
						</video>
					</div>
					{/*<div className="image-sectionwraper"><img src={blockimageone} alt="blockimageon" /></div>*/}
				</div>
				<div className="right-block col-md-6 text-blockwraper">
					<div className="subtitle-descrription-wraper">
						<h2 className="subtitle">
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}>Learn English with <span className="highlighted-text">ZERO</span> doubts.</span>
								<span className={(language==="hi")?"active":""}>अंग्रेज़ी सीखें <span className="highlighted-text">बिना दुविधा</span> के।</span>
								<span className={(language==="ta")?"active":""}>ஆங்கிலத்தை சந்தேகமே <span className="highlighted-text">இல்லாமல்</span> கற்றுக்கொள்ளுங்கள்</span>
							</span>
						</h2>
						<div className="descptionwraper">
							<Trans>With over 400 professionally crafted video lessons presented in Hindi or Tamil, you’re guaranteed to understand English at a deep level, like a native speaker.
								See for yourself,
							</Trans>
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}><Link className="links-wraper" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"> download the app for a free trial.</Link></span>
								<span className={(language==="hi")?"active":""}><Link className="links-wraper" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"> ट्रायल के लिए इस ऐप को डाउनलोड करें।</Link></span>
								<span className={(language==="ta")?"active":""}><Link className="links-wraper" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"> இலவசமாகத் தரவிறக்கி பயன்படுத்திப் பார்த்து நீங்களே உறுதிப்படுத்திக்கொள்ளுங்கள்.</Link></span>
							</span> 
						</div>
					</div>
				</div>
			</div>  
			<div className="row block-wraper description-imagewraper section_two">
				<div className="left-block col-md-6 text-blockwraper">
					<div className="subtitle-descrription-wraper">
						<h2 className="subtitle">
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}>A curriculum <span className="highlighted-text">customized</span> to your exact level.</span>
								<span className={(language==="hi")?"active":""}><span className="highlighted-text">विशेष</span> रूप से आपको ध्यान में रख कर बनाया गया पाठ्यक्रम।</span>
								<span className={(language==="ta")?"active":""}>உங்களுடைய நிலைக்கு ஏற்ப <span className="highlighted-text">வடிவமைக்கப்பட்ட</span> பாடத்திட்டம் </span>
							</span>
							 <span className="highlighted-text"></span> 
						</h2>
						<div className="descptionwraper">
							<Trans>It doesn’t matter if you’re a complete beginner or already know some English because Talking Yak’s comprehensive pre-assessment builds a customized curriculum exactly suited to every learner’s level.</Trans>
						</div>
					</div>
				</div>
				<div className="right-block col-md-6 image-block">
					<div className="video-sectionwraper">
						<div className="image-sectionwraper">
							<img src={custumised} alt="custumised" />
						</div>
					</div>
				</div>
			</div>
			<div className="row block-wraper image-descriptionwraper section_three">
				<div className="left-block col-md-6 image-block">
					<div className="video-sectionwraper">
						<div className="video-sectionwraper">
							<div className="image-sectionwraper">
								<img src={speach} alt="speach" />
							</div>
						</div>
					</div>
				</div>
				<div className="right-block col-md-6 text-blockwraper">
					<div className="subtitle-descrription-wraper">
						<h2 className="subtitle">
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}>Best in class <span className="highlighted-text">speech</span> technology</span>
								<span className={(language==="hi")?"active":""}>उत्तम क्लास <span className="highlighted-text">स्पीच</span> तकनीक</span>
								<span className={(language==="ta")?"active":""}>இருப்பதிலேயே மிகச்சிறந்த <span className="highlighted-text">பேச்சுத்</span> தொழில்நுட்பம்</span>
							</span>
						</h2>
						<div className="descptionwraper">
							<Trans>Talking Yak harnesses the power of the world’s most advanced speech recognition technology, allowing you to perfect your pronunciation and build your confidence for conversations.</Trans>
						</div>
					</div>
				</div>
			</div>
			<div className="row block-wraper description-imagewraper section_four">
				<div className="left-block col-md-6 text-blockwraper">
					<div className="subtitle-descrription-wraper">
						<h2 className="subtitle">
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}>A personalised <span className="highlighted-text">mastery</span> coach advises you at each step of your learning.</span>
								<span className={(language==="hi")?"active":""}>एक <span className="highlighted-text">व्यक्तिगत</span> कोच आपको अंग्रेजी सीखने में हर कदम पर आपकी सहायता करेगा।</span>
								<span className={(language==="ta")?"active":""}>உங்கள் கற்றலின் ஒவ்வொரு படியிலும் ஒரு <span className="highlighted-text">பிரத்யேகமான</span> பயிற்சியாளர் அறிவுரை வழங்குவார்.</span>
							</span>
						</h2>
						<div className="descptionwraper">
							<Trans>Using the most advanced algorithms available, Talking Yak automatically monitors your performance - and makes intelligent recommendations to make sure you get the extra practice when you need it.</Trans>
						</div>
					</div>
				</div>
				<div className="right-block col-md-6 image-block">
					<div className="video-sectionwraper">
						<video loop="true" autoplay="autoplay" muted>
							<source src={PersonalisedYak} type="video/mp4" />
							<track src={PersonalisedYak} kind="PersonalisedYak" srclang="en" label="english_captions"></track>
						</video>
					</div>
				</div>
			</div>
        </div>
		<div className="home-sectionone-wraper home-sectionfour-wraper">
            <div className="row home-sectionone-wraped container-max-width">
				<div className="col-md-6 left-block">
					<div className="image-sectionwraper"><img src={navphone} alt="navphone" /></div>
				</div>
				<div className="col-md-6 right-block">
					<div className="titleitalic-descriptionwraped">
						<h3 className="subtitle">
							<Trans>Learn up to 7 times faster than conventional methods.</Trans>
						</h3>
						<div className="descptionwraper">
							<Trans>By teaching English through Hindi or Tamil, and by customizing a curriculum to match each learner’s level and capabilities, study after study proves Talking Yak prepares learners faster than alternatives.</Trans>
						</div>
						<div className="btn-linksectionwraper">
							<div className="downloadlinkwraper witharrow">
							<Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><span className="link-text"><Trans>Download Now</Trans></span><span className="link__icon"></span></Link>
							</div>
							<div className="googlepay-linkwraper">
								<Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><img src={videogooglepay} alt="googlepay" /></Link>
							</div>
						</div>
						<div className="italic_subtitle-learnerwraper">
							<div className="italic-textwraper">“<Trans>I’ve learned more in the first three months of using Talking Yak than I did in 12 years of school.</Trans>”</div>
							<div className="learner-textwraper"><Trans>LEARNER, Age 18</Trans></div>
						</div>
					</div>
				</div>
            </div>  
        </div>
		<div className="home-sectionfive-wraper">
			<div className="home-sectionfive-wraped container-max-width">
				<div className="row subtitle-desc-wraper">
					<div className="subtitle-desc-wraped col-md-12">
						<h3 className="subtitle">
							<Trans>Learn all the essential skills to bring mastery to your English.</Trans>
						</h3>
						<div className="descptionwraper">
							<Trans>Talking Yak is currently being used in schools, colleges, businesses and NGOs. Now, Talking Yak is being made available to the general public.</Trans>
						</div>
					</div>	
				</div>
				<div className="list-items-wraper row">
					<div className="item-list col-md-2">
						<div className="items-wraper">
							<div className="image-wraper">
								<img src={reading} alt="imageone" />
							</div>
							<h4 className="title-wraper"><Trans>Reading</Trans></h4>
							<div className="descptionwraper">
								<Trans>Our sound-based phonics approach teaches students to read words without memorisation, increasing speed and comprehension.</Trans>
							</div>
						</div>
					</div>
					<div className="item-list col-md-2">
						<div className="items-wraper">
							<div className="image-wraper">
								<img src={book} alt="imagettg" />
							</div>
							<h4 className="title-wraper"><Trans>Vocabulary</Trans></h4>
							<div className="descptionwraper">
								<Trans>With access to over 10000 words and a local keyboard search, the learner can be prepared for general and specialised situations.</Trans>
							</div>
						</div>
					</div>
					<div className="item-list col-md-2">
						<div className="items-wraper">
							<div className="image-wraper">
								<img src={message} alt="message" />
							</div>
							<h4 className="title-wraper"><Trans>Conversations</Trans></h4>
							<div className="descptionwraper">
								<Trans>Thousands of essential conversational phrases teach learners to interact with ease and fluency in a wide array of social situations.</Trans>
							</div>
						</div>
					</div>
					<div className="item-list col-md-2">
						<div className="items-wraper">
							<div className="image-wraper">
								<img src={grammer} alt="imageg" />
							</div>
							<h4 className="title-wraper"><Trans>Grammar</Trans></h4>
							<div className="descptionwraper">
								<Trans>Our scaffolded, patterned approach teaches learners to express themselves with grammatical perfection across all major tenses.</Trans>
							</div>
						</div>
					</div>
					<div className="item-list col-md-2">
						<div className="items-wraper">
							<div className="image-wraper">
								<img src={speaking} alt="imagew" />
							</div>
							<h4 className="title-wraper"><Trans>Speaking</Trans></h4>
							<div className="descptionwraper">
								<Trans>Harnessing the industry’s leading speech engine, our proprietary solution allows unlimited practice to improve pronunciation.</Trans>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="home-sectionsix-wraper">
			<div className="row home-sectionsix-wraped container-max-width">
				<div className="cleintspeakswraper col-md-9">
					<div className="list-itemsmain-wraper row">
						<Swiper
							modules={[Navigation]}
							spaceBetween={3}
							breakpoints={{
								576: {
								// width: 576,
								slidesPerView: 1,
								},
								768: {
								// width: 768,
								slidesPerView: 3,
								},
							}}
							navigation={{ clickable: true }}
							>
							<SwiperSlide>
								<div className="item-listwraper">
									<div className="image-descsectionwraepr">
										<div className="video-sectionwraper">
											<video loop="true" autoplay="autoplay">
												<source src={PersonalisedYak} type="video/mp4" />
												<track src={PersonalisedYak} kind="PersonalisedYak" srclang="en" label="english_captions"></track>
											</video>
										</div>
										<div className="description-designationwraper">
											<div className="descptionwraper">
											<Trans>“Our research showed that overall, our state’s 
											teachers didn’t possess the level of English
												knowledge we wanted them to have. Talking Yak 
												provided access to teachers across the state.
												The results were amazing. By learning through Hindi, 
												they gained a deep understanding of the way English works…</Trans>
											</div>
											<div className="design-wraper">
												SUDISH,<br/> SSA
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="item-listwraper">
									<div className="image-descsectionwraepr">
										<div className="video-sectionwraper">
											<video loop="true" autoplay="autoplay">
												<source src={PersonalisedYak} type="video/mp4" />
												<track src={PersonalisedYak} kind="PersonalisedYak" srclang="en" label="english_captions"></track>
											</video>
										</div>
										<div className="description-designationwraper">
											<div className="descptionwraper">
											<Trans>“Hope partners with companies such as Microsoft, 
											Dell, SAP, Juniper, United Way and Barclays, 
											We serve over 2 lakh individuals every year across India.
												When English learning is needed, Talking Yak is by far 
												the best solution…</Trans>
											</div>
											<div className="design-wraper">
											IAN CORREA,<br/> Hope Foundation
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="item-listwraper">
									<div className="image-descsectionwraepr">
										<div className="video-sectionwraper">
											<video loop="true" autoplay="autoplay" muted>
												<source src={PersonalisedYak} type="video/mp4" />
												<track src={PersonalisedYak} kind="PersonalisedYak" srclang="en" label="english_captions"></track>
											</video>
										</div>
										<div className="description-designationwraper">
											<div className="descptionwraper">
											<Trans>“We support thousands of kids through the Agaram foundation.
											We’ve been partners with Talking Yak for years. It is without
												question the best way to learn English. Many of our students 
												have little to no English knowledge, but with Talking Yak, 
												they master the essential skills in just a few months…</Trans>
											</div>
											<div className="design-wraper">
												ACTOR SURIYA,<br/> Agaram Foundation
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="item-listwraper">
									<div className="image-descsectionwraepr">
										<div className="video-sectionwraper">
											<video loop="true" autoplay="autoplay" muted>
												<source src={PersonalisedYak} type="video/mp4" />
												<track src={PersonalisedYak} kind="PersonalisedYak" srclang="en" label="english_captions"></track>
											</video>
										</div>
										<div className="description-designationwraper">
											<div className="descptionwraper">
											<Trans>“We support thousands of kids through the Agaram foundation.
											We’ve been partners with Talking Yak for years. It is without
												question the best way to learn English. Many of our students 
												have little to no English knowledge, but with Talking Yak, 
												they master the essential skills in just a few months…</Trans>
											</div>
											<div className="design-wraper">
												ACTOR SURIYA,<br/> Agaram Foundation
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
				<div className="subtitle-descmain-wraper col-md-3">
					<div className="subtitle-descrription-wraper">
						<h2 className="subtitle">
							<Trans>Yup, that’s what they said</Trans>
						</h2>
						<div className="descptionwraper">
							<Trans>Join the thousands of learners who have already mastered English with Talking Yak. Investing just a few minutes per day, you'll join them in no time!  Guaranteed!</Trans>
						</div>
						<div className="btn-linksectionwraper">
							<div className="downloadlinkwraper witharrow">
							<Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><span className="link-text"><Trans>Download Now</Trans></span><span className="link__icon"></span></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="home-sectionseven-wraper client-sectionwraper">
			<div className="home-sectionseven-wraped container-max-width client-sectionwraped">
				<div className="row client-sectionwraped-inner">
					<div className="col-md-4 descptionwraper">
						<div className="descptionwraped"><Trans>Trusted by leading multi-national corporations, start-ups & small businesses</Trans></div>
					</div>
					<div className="col-md-8 client-items-wraper">
						<div className="row items-wraper">
							<Swiper
									modules={[Navigation,Autoplay]}
									spaceBetween={3}
									loop={false}
									breakpoints={{
										767: {
										// width: 576,
										slidesPerView: 1,
										},
										768: {
										// width: 768,
										slidesPerView: 4,
										},
									}}
									autoplay={{
										delay: 2000,
										disableOnInteraction:false,
									  }}
									navigation={{ clickable: true }}
									className="clientsSwiper"
									>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientone} alt="clientone" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientfour} alt="clientfour" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientthree} alt="clienthtree" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clienttwo} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientfive} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientsix} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientseven} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clienteight} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientnine} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientten} alt="clienttwo" />
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="client-list col">
											<img src={clientleven} alt="clienttwo" />
										</div>
									</SwiperSlide>
							</Swiper>
						</div>
					</div>	
				</div>
			</div>
		</div>
    </div>
   </Layout>
  )
}

export const query = graphql`
{
	locales: allLocale {
	  edges {
		node {
		  language
		  ns
		  data
		}
	  }
	}
}
`
